import { Routes } from '@angular/router';
import { canActivateTeam } from './auth/auth-gaurd';

export const routes: Routes = [
  {
    path: 'layout',
    canActivate: [canActivateTeam],
    loadChildren: () => import('./components/layout/routes'),
  },
  {
    path: 'sign-in',
    loadComponent: () =>
      import('./components/sign-in/sign-in.component').then(
        (m) => m.SignInComponent
      ),
  },
  {
    path: 'not-found',
    loadComponent: () =>
      import('./components/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
  },
  {
    path: ':domain/auth-callback',
    redirectTo: 'layout',
    pathMatch: 'full',
  },
  { path: '', redirectTo: 'layout', pathMatch: 'full' },
  { path: '**', redirectTo: '/not-found', pathMatch: 'full' },
];
